import React from 'react';
import './WorkWithMe.css';

function WorkWithMe() {
  return (
    <div className="work-with-me">
      <header className="work-with-me-header">
        <h1>Work with Me</h1>
      </header>
      <main className="work-with-me-main">
        <div className="work-with-me-content">
          <p>
          Welcome to my world of innovation and sustainable growth. I invite you to join me in shaping the future through impactful collaborations across a diverse spectrum of ventures. Whether you are inspired by innovation and Information Technology solutions with JW Infotech, seeking career enrichment through Concepts and Careers and Growth University, or eager to pioneer breakthroughs in technology with InnoNexus, there's a place for you here.
          </p>
          <p>
          At Begampura Farm, we are redefining organic agriculture with sustainable practices, while Perfect Fitness Team empowers individuals to achieve their wellness goals. AOneXus offers comprehensive business solutions to streamline operations and drive sustainable growth, while PayRental ensures seamless property management experiences for landlords and tenants alike. NexusTeq stands ready to innovate and integrate advanced technologies into your business strategy.
          </p>
          <p>
          For those navigating the real estate landscape, TREGCOM provides expert guidance and investment opportunities, and JW EXIM facilitates international trade connections. Explore elegance and craftsmanship with JW Jewellers, where every piece tells a story of timeless beauty.
          </p>
          <p>
          Together, let's forge new paths, create meaningful impact, and shape a future defined by innovation and excellence. Join me in building a legacy of success and sustainability in every endeavor we undertake.
          </p>
        </div>
        <div className="work-with-me-image">
          <img src="https://i.ibb.co/Bzc8PH6/i-Stock-1160000498.jpg" alt="Handshake" />
        </div>
      </main>
    </div>
  );
}

export default WorkWithMe;
